import React, {useState} from 'react'
import axios from 'axios';
import logotype from './logotype.png'
import './App.css'

// let API_URL = 'http://localhost:3000'
let API_URL = 'https://anaglyph.video/api'

if (process.env.NODE_ENV == "production") {
  API_URL = 'https://anaglyph.video/api'
}

const App = () => {

  const [file, setFile] = useState(null)
  const [url, setUrl] = useState(null)
  const [token, setToken] = useState(null)
  const [title, setTitle] = useState(null)
  const [uploadSuccessful, setUploadSuccessful] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUploadFormPresented, setIsUploadFormPresented] = useState(false)

  const [isEmailPresented, setEmailPresented] = useState(true)
  const [email, setEmail] = useState(null)
  const [isLogging, setIsLogging] = useState(false)

  const [isVerificationCodePresented, setVerificationCodePresented] = useState(false)
  const [verificationCode, setVerificationCode] = useState(null)
  const [isVerifyingCode, setIsVerifyingCode] = useState(false)
  const [verificationId, setVerificationId] = useState(null)


  const handleInputChange = event => {
    // Add the file to state
    setFile(event.target.files[0])
  }

  const handleUrlChange = event => {
    setUrl(event.target.value);

  }

  const handleTokenChange = event => {
    setToken(event.target.value);
  }

  const handleTitleChange = event => {
    setTitle(event.target.value);
  }

  const onEmailChange = event => {
    setEmail(event.target.value)
  }

  const onVerificationCodeChange = event => {
    setVerificationCode(event.target.value)
  }

  const onLoginSubmit = async event => {
    event.preventDefault()
    setIsLogging(true)

    const response = await fetch(`${API_URL}/auth/creator`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })

    if (response.status == 200 || response.status == 204) {
      const data = await response.json()
      setVerificationId(data.verificationId)
      setVerificationCodePresented(true)
    }
  }

  const onVerificationSubmit = async event => {
    event.preventDefault()
    setIsVerifyingCode(true)

    const response = await fetch(`${API_URL}/auth/verification`, {
      method: 'POST',
      body: JSON.stringify({
        verificationId: verificationId,
        code: verificationCode,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })

    if (response.status == 200 || response.status == 204) {
      const data = await response.json()
      console.log(data)
      setToken(data.token)
      setIsUploadFormPresented(true)
    } else {
      setIsVerifyingCode(false)
    }
  }

  const handleSubmit = async event => {
    // Don't do a real form submit
    event.preventDefault()

    setIsSubmitting(true)

    const signedUrl = await getSignedUrl()
    //const signedUrl = url;

    try {
      await uploadFile(signedUrl)
      await postVideo(signedUrl.id)
      console.log('uploaded');
    }
    catch (err) {
      setIsSubmitting(false)
      console.log(err)
      alert('There was an error uploading your file.')
      throw err
    }

    setIsSubmitting(false)
    alert('Upload success')
  }

  const getSignedUrl = async () => {
    const body = {
      contentType: 'video/mp4',
      width: 3840,
      height: 1080,
      duration: 5,
      fps: 30,
    }

    const authorization = "Bearer " + token;
    const response = await fetch(`${API_URL}/upload/video`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authorization,
      }
    })

    const data = await response.json()

    return data

    // const {uploadUrl} = await response.json()

    // return uploadUrl
  }

  const uploadFile = async data => {

    console.log(file.type);

    // let form = new FormData();
    // //Object.keys(data.fields).forEach(key => form.append(key, data.fields[key]));
    // form.append('file', file, { filename: 'test.mp4'});

    // const response = await axios.post(signedUrl, form, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });

    // await fetch(signedUrl, { 
    //     method: 'POST', 
    //     body: form,
    //     header: {
    //         // 'Content-Type': file.type,
    //         // 'x-amz-acl': 'public-read',
    //     }
    // });

    
    let uploadData = data.multipartData;

    let form = new FormData();
    Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
    // form.append('Content-Type', 'video/mp4');
    form.append('file', file);

    await fetch(uploadData.url, { 
        method: 'POST', 
        body: form,
        header: {
            //  'Content-Type': 'video/mp4',
             'x-amz-acl': 'public-read',

        }
    });

    // TODO: Post video

    // const res = await fetch(signedUrl, {
    //   method: 'PUT',
    //   body: file,
    //   headers: {
    //     // 'Content-Type': file.type,
    //     // 'x-amz-acl': 'public-read',
    //   }
    // })



    // const res = await Axios.put(signedUrl, file, {
    //     header: {
    //         "Access-Control-Allow-Origin": "*",
    //         'Content-Type': file.type,
    //         'x-amz-acl': 'public-read',
    //     }
    // })

  }

  const postVideo = async uploadId => {

    const body = {
      title: title,
      uploadId: uploadId
    }

    const authorization = "Bearer " + token;
    const response = await fetch(`${API_URL}/video`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authorization,
      }
    })
  }

  if (isUploadFormPresented) {
    return (
      <div className="background">
        <div className="content">
          <img src={logotype} />
          <p>Select a side-by-side video to upload (resolution: 3840x1080px)</p>
          <form onSubmit={handleSubmit}>
            <input type="text" name="title" placeholder="Video Title" onChange={handleTitleChange} />
            <input type="file" name="file" onChange={handleInputChange} />
            <input type="submit" value="Upload" disabled={isSubmitting} />
          </form> 
        </div>
      </div>
    )
  }

  if (isVerificationCodePresented) {
    return (
      <div className="background">
        <div className="content">
          <img src={logotype} />
          <form onSubmit={onVerificationSubmit}>
            <input type="text" name="code" placeholder="Verification Code" onChange={onVerificationCodeChange} />
            <input type="submit" value="Verify" disabled={isVerifyingCode} />
          </form>
        </div>
      </div>
    )
  }

  if (isEmailPresented) {
    return (
      <div className="background">
        <div className="content">
          <img src={logotype} />
          <form onSubmit={onLoginSubmit}>
            <input type="email" name="email" placeholder="Enter e-mail" onChange={onEmailChange} />
            <input type="submit" value="Continue" disabled={isLogging} />
          </form>
        </div>
      </div>
    )
  }

}

export default App